import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/professionnel.css';
import {Row, Col} from 'react-bootstrap';


import windows from '../../img/professionnel/windows.png';
import linux from '../../img/professionnel/linux.png';
import cisco from '../../img/professionnel/cisco.png';

import docker from '../../img/professionnel/docker.png';
import sql from '../../img/professionnel/langageSQL.png';
import azure from '../../img/professionnel/azure.png';

import powerShell from '../../img/professionnel/powerShell.png';
import fortinet from '../../img/professionnel/fortinet.png';
import wireshark from '../../img/professionnel/wireshark.png';

import bash from '../../img/professionnel/bash.png';
import vmware from '../../img/professionnel/vmware.png';
import postgreSQL from '../../img/professionnel/postgreSQL.png';

import moocGDP from '../../img/professionnel/moocGDP.png';

const Competence = () => {
    return (
        <div>
            <Row>
                <Col xs={12} md={12} lg={4} id="titreCompetence">
                    <h2>Compétences :</h2>
                </Col>
                <Col xs={12} md={12} lg={4}>
                    <Row className="textImgCompetence">
                        <Col xs={4} md={4} lg={4}>
                            <img src={windows} alt="imageOffice"/>
                            <h3>Windows Server</h3>
                        </Col>
                        <Col xs={4} md={4} lg={4}>
                            <img src={linux} alt="imageOffice"/>
                            <h3>Linux</h3>
                        </Col>
                        <Col xs={4} md={4} lg={4}>
                            <img src={cisco} alt="imageOffice"/>
                            <h3>Cisco</h3>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={4}>
                    <Row className="textImgCompetence">
                        <Col xs={4} md={4} lg={4}>
                            <img src={sql} alt="imageOffice"/>
                            <h3>Language SQL</h3>
                        </Col>
                        <Col xs={4} md={4} lg={4}>
                            <img src={docker} alt="imageOffice"/>
                            <h3>Docker</h3>
                        </Col>
                        <Col xs={4} md={4} lg={4}>
                            <img src={azure} alt="imageOffice"/>
                            <h3>Azure</h3>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={4} id="titreCompetence">
                    <h2></h2>
                </Col>
                <Col xs={12} md={12} lg={4}>
                    <Row className="textImgCompetence">
                        <Col xs={4} md={4} lg={4}>
                            <img src={fortinet} alt="imageOffice"/>
                            <h3>Fortinet</h3>
                        </Col>
                        <Col xs={4} md={4} lg={4}>
                            <img src={wireshark} alt="imageOffice"/>
                            <h3>Wire Shark</h3>
                        </Col>
                        <Col xs={4} md={4} lg={4}>
                            <img src={powerShell} alt="imageOffice"/>
                            <h3>PowerShell</h3>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={4}>
                    <Row className="textImgCompetence">
                        <Col xs={4} md={4} lg={4}>
                            <img src={postgreSQL} alt="imageOffice"/>
                            <h3>PostgreSQL</h3>
                        </Col>
                        <Col xs={4} md={4} lg={4}>
                            <img src={vmware} alt="imageOffice"/>
                            <h3>VMware</h3>
                        </Col>
                        <Col xs={4} md={4} lg={4}>
                            <img src={bash} alt="imageOffice"/>
                            <h3>Bash</h3>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{paddingTop: '2em'}}>
                <Col xs={12} md={12} lg={4} id="titreCompetence">
                    <h2>Certifications :</h2>
                </Col>
                
                <Col xs={12} md={12} lg={4}>
                    <Row xs={12} md={12} lg={12} className="textImgCompetence">
                        <Col xs={6} md={6} lg={6}>
                            <img src={cisco} alt="imageOffice"/>
                            <h3>CyberOps Association</h3>
                            <p>Connaisances et compétences liées aux opérations de cybersécurité</p>
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                            <img src={cisco} alt="imageOffice"/>
                            <h3>Network Security</h3>
                            <p>Compétences nécessaires pour protéger les réseaux et empêcher les intrusions</p>
                        </Col>
                    </Row>
                </Col>
                
                <Col xs={12} md={12} lg={4}>
                    <Row xs={12} md={12} lg={12} className="textImgCompetence">
                        <Col xs={6} md={6} lg={6}>
                            <img src={cisco} alt="imageOffice"/>
                            <h3>NDG Linux Essentials</h3>
                            <p>Compétences nécessaires pour travailler avec le système d'exploitation Linux</p>
                        </Col>
                        <Col xs={6} md={6} lg={6}>
                            <img src={moocGDP} alt="imageOffice"/>
                            <h3>Gestion de projet</h3>
                            <p>Connaissances et compétences nécessaires à la gestion et la conduite de projet</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Competence;