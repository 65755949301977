import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_6008 from '../../img/creation/voyage/tarnos/IMG_6008.jpg';
import IMG_6010 from '../../img/creation/voyage/tarnos/IMG_6010.jpg';
import IMG_6149 from '../../img/creation/voyage/tarnos/IMG_6149.jpg';
import IMG_6199 from '../../img/creation/voyage/tarnos/IMG_6199.jpg';
import IMG_6375 from '../../img/creation/voyage/tarnos/IMG_6375.jpg';
import IMG_6415 from '../../img/creation/voyage/tarnos/IMG_6415.jpg';

const VoyageTarnos = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Tarnos</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_6008} alt="IMG_6008"/>
                            <img src={IMG_6199} alt="IMG_6199"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_6010} alt="IMG_6010"/>
                            <img src={IMG_6375} alt="IMG_6375"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_6149} alt="IMG_6149"/>
                            <img src={IMG_6415} alt="IMG_6415"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default VoyageTarnos;