import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_1372 from '../../img/creation/paysage/citeDesSciences/IMG_1372.jpg';
import IMG_1374 from '../../img/creation/paysage/citeDesSciences/IMG_1374.jpg';
import IMG_1377 from '../../img/creation/paysage/citeDesSciences/IMG_1377.jpg';
import IMG_1459 from '../../img/creation/paysage/citeDesSciences/IMG_1459.jpg';
import IMG_1494 from '../../img/creation/paysage/citeDesSciences/IMG_1494.jpg';
import IMG_1532 from '../../img/creation/paysage/citeDesSciences/IMG_1532.jpg';

const PaysageCite = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Cité des sciences</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_1377} alt="IMG_1377"/>
                            <img src={IMG_1532} alt="IMG_1532"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_1494} alt="IMG_1494"/>
                            <img src={IMG_1374} alt="IMG_1374"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_1372} alt="IMG_1372"/>
                            <img src={IMG_1459} alt="IMG_1459"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default PaysageCite;