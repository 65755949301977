import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_5527 from '../../img/creation/nocturne/illuminationsJardinDesPlantes/IMG_5527.jpg';
import IMG_5529 from '../../img/creation/nocturne/illuminationsJardinDesPlantes/IMG_5529.jpg';
import IMG_5559 from '../../img/creation/nocturne/illuminationsJardinDesPlantes/IMG_5559.jpg';
import IMG_5575 from '../../img/creation/nocturne/illuminationsJardinDesPlantes/IMG_5575.jpg';
import IMG_5604 from '../../img/creation/nocturne/illuminationsJardinDesPlantes/IMG_5604.jpg';
import IMG_5638 from '../../img/creation/nocturne/illuminationsJardinDesPlantes/IMG_5638.jpg';
import IMG_5646 from '../../img/creation/nocturne/illuminationsJardinDesPlantes/IMG_5646.jpg';
import IMG_5654 from '../../img/creation/nocturne/illuminationsJardinDesPlantes/IMG_5654.jpg';
import IMG_5710 from '../../img/creation/nocturne/illuminationsJardinDesPlantes/IMG_5710.jpg';
import IMG_5877 from '../../img/creation/nocturne/illuminationsJardinDesPlantes/IMG_5877.jpg';



const NocturneIlluminationJardinDesPlantes = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Illuminations Jardin des Plantes</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_5527} alt="IMG_1823"/>
                            <img src={IMG_5529} alt="IMG_1831"/>
                            <img src={IMG_5559} alt="IMG_1831"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_5575} alt="IMG_1838"/>
                            <img src={IMG_5604} alt="IMG_1844"/>
                            <img src={IMG_5638} alt="IMG_1879"/>
                            <img src={IMG_5646} alt="IMG_1831"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_5654} alt="IMG_1846"/>
                            <img src={IMG_5710} alt="IMG_1853"/>
                            <img src={IMG_5877} alt="IMG_1853"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default NocturneIlluminationJardinDesPlantes;