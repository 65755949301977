import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/creation.css';
import { Container} from 'react-bootstrap';
import CarteType from '../elements/creation/CarteType';


import imgParis from '../img/creation/paysage/paris/parisCover.jpg';
import imgCite from '../img/creation/paysage/citeDesSciences/citeCover.jpg';
import imgSoufriere from '../img/creation/paysage/soufriere/soufriereCover.jpg';


const CreationPaysage = () => {
    
    let Paris = <CarteType titre="Paris" image={imgParis} couleur="rouge" lien = "/Creation-paysage/Paris"/>
    let Citedessciences = <CarteType titre="Cité des sciences" image={imgCite} couleur="jaune" lien = "/Creation-paysage/Cite-des-sciences"/>
    let Soufriere = <CarteType titre="Soufrière" image={imgSoufriere} couleur="bleu" lien = "/Creation-paysage/Soufriere"/>

    return (
        <div id="creation">
            <div id="entete" className="header"></div>
            <div id="titre">
                <h2>Paysages</h2>
            </div>
            <Container>
                <div className="containerCarte">
                        {Paris}
                        {Citedessciences}
                        {Soufriere}
                </div>
            </Container>
        </div>
    );
};

export default CreationPaysage;