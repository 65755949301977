import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/creation.css';
import { Container, Row} from 'react-bootstrap';
import CarteType from '../elements/creation/CarteType';


import imgTarnos from '../img/creation/voyage/tarnos/tarnosCover.jpg';
import imgYatchClubECE from '../img/creation/voyage/croisiereYatchClubECE2021/croisiereCover.jpg';
import imgBayonne from '../img/creation/voyage/bayonne/bayonneCover.jpg';
import imgGuadeloupe from '../img/creation/voyage/guadeloupe/guadeloupeCover.jpg';
import imgAngers from '../img/creation/voyage/angers/angersCover.jpg';
import imgSkiBDS from '../img/creation/voyage/skiBDSECE2022/skiBDSCover.jpg';


const CreationVoyage = () => {
    
    let Tarnos = <CarteType titre="Tarnos" image={imgTarnos} couleur="orange" lien = "/Creation-voyage/Tarnos"/>
    let YatchClubECE = <CarteType titre="Croisière Yacht CLub ECE 2021" image={imgYatchClubECE} couleur="bleu" lien = "/Creation-voyage/Croisiere-yatch-club-ece-2021"/>
    let Bayonne = <CarteType titre="Bayonne" image={imgBayonne} couleur="vert" lien = "/Creation-voyage/Bayonne"/>
    let Guadeloupe = <CarteType titre="Guadeloupe" image={imgGuadeloupe} couleur="vert" lien = "/Creation-voyage/Guadeloupe"/>
    let Angers = <CarteType titre="Angers" image={imgAngers} couleur="jaune" lien = "/Creation-voyage/Angers"/>
    let SkiBDS = <CarteType titre="Ski BDS ECE 2022" image={imgSkiBDS} couleur="orange" lien = "/Creation-voyage/Ski-BDS-ECE-2022"/>

    return (
        <div id="creation">
            <div id="entete" className="header"></div>
            <div id="titre">
                <h2>Voyages</h2>
            </div>
            <Container>
                <div className="containerCarte">
                        {SkiBDS}
                        {Tarnos}
                        {YatchClubECE}
                        {Bayonne}
                        {Guadeloupe}
                        {Angers}
                </div>
            </Container>
        </div>
    );
};

export default CreationVoyage;