import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_2667 from '../../img/creation/portrait/pro/IMG_2667.jpg';
import IMG_2896 from '../../img/creation/portrait/pro/IMG_2896.jpg';
import IMG_3038 from '../../img/creation/portrait/pro/IMG_3038.jpg';
import IMG_3147 from '../../img/creation/portrait/pro/IMG_3147.jpg';
import IMG_3165 from '../../img/creation/portrait/pro/IMG_3165.jpg';
import IMG_3180 from '../../img/creation/portrait/pro/IMG_3180.jpg';


const PortraitPro = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Professionnelle</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_2667} alt="IMG_2667"/>
                            <img src={IMG_2896} alt="IMG_2896"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_3147} alt="IMG_3147"/>
                            <img src={IMG_3038} alt="IMG_3038"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_3180} alt="IMG_3180"/>
                            <img src={IMG_3165} alt="IMG_3165"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default PortraitPro;