import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_7121 from '../../img/creation/voyage/skiBDSECE2022/IMG_7121.jpg';
import IMG_7273 from '../../img/creation/voyage/skiBDSECE2022/IMG_7273.jpg';
import IMG_7638 from '../../img/creation/voyage/skiBDSECE2022/IMG_7638.jpg';
import IMG_7729 from '../../img/creation/voyage/skiBDSECE2022/IMG_7729.jpg';
import IMG_7736 from '../../img/creation/voyage/skiBDSECE2022/IMG_7736.jpg';
import IMG_7740 from '../../img/creation/voyage/skiBDSECE2022/IMG_7740.jpg';

const VoyageSkiBDS = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Ski BDS ECE 2022</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_7273} alt="IMG_7273"/>
                            <img src={IMG_7638} alt="IMG_7638"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_7121} alt="IMG_7121"/>
                            <img src={IMG_7729} alt="IMG_7729"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_7740} alt="IMG_7740"/>
                            <img src={IMG_7736} alt="IMG_7736"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default VoyageSkiBDS;