import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/navbar.css';
import { Row, Col} from 'react-bootstrap';

class NavBar extends React.Component{
    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    
    handleClick(){
        let menuDeroulant = document.getElementById("menuDeroulant");
        let barBottom = document.getElementById("bar-bottom");
        let barTop = document.getElementById("bar-top");
        let barMidL = document.getElementById("bar-mid-l");
        let barMidR = document.getElementById("bar-mid-r");
        let header = document.getElementsByClassName("header")[0];
        if(getComputedStyle(menuDeroulant).display != "none"){
            menuDeroulant.style.display = "none";
            barBottom.style.transform = "rotate(0deg)";
            barTop.style.transform = "rotate(0deg)";
            barMidL.style.opacity = "1";
            barMidR.style.opacity = "1";
            barMidL.style.transform = "translate(0, 0)";
            barMidR.style.transform = "translate(0, 0)";
            header.style.display = "block";
        } else {
            menuDeroulant.style.display = "flex";
            menuDeroulant.style.animationName = "enter";
            menuDeroulant.style.animationDuration = "500ms";
            barBottom.style.transform = "rotate(-45deg) translate(-2.7em, -1em)";
            barTop.style.transform = "rotate(45deg)";
            barMidL.style.opacity = "0";
            barMidR.style.opacity = "0";
            window.scrollTo(0, 0);
            header.style.display = "none";
        }
    }




    render(){
        
        return (
        <div>
            <div className="navbar">
                <Row>
                    <Col xs={8} md={4} lg={4}>
                        <h1 className="logoNav"><a href="/">Théric PHERON</a></h1>
                    </Col>
                    <Col xs={3} md={2} lg={2}>
                        <button id="boutonNav" onClick={this.handleClick}>
                            <svg version="1.1" id="menuIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 6 64 54.8">
                            <path id="bar-bottom" class="st0" d="M6.8,43.1h51.1c1.2,0,2.2,1.3,2.2,2.8l0,0c0,1.5-1,2.8-2.2,2.8H6.8c-1.2,0-2.2-1.3-2.2-2.8l0,0
                                C4.6,44.3,5.6,43.1,6.8,43.1z"/>
                            <path id="bar-top" class="st0" d="M6.8,6h51.1c1.2,0,2.2,1.2,2.2,2.7l0,0c0,1.5-1,2.7-2.2,2.7H6.8c-1.2,0-2.2-1.2-2.2-2.7l0,0
                                C4.6,7.2,5.6,6,6.8,6z"/>
                            <path id="bar-mid-l" d="M10.7,24.7c-1,0-1.9,1.2-1.9,2.7s0.8,2.7,1.9,2.7h21.6v-5.4C32.2,24.7,10.7,24.7,10.7,24.7z"/>
                            <path id="bar-mid-r" d="M54,24.7H32v5.4H54c1,0,1.9-1.2,1.9-2.7S55.1,24.7,54,24.7z"/>
                            </svg>
                        </button>
                    </Col>
                    <Col xs={1} md={6} lg={6}>
                        <div className="sectionListe">
                            <ul className="listeNav">
                                <li className="item"><a href="/" id="aAccueil">Accueil</a></li>
                                <li className="item"><a href="/Professionnel" id="aProfessionnel">Professionnel</a></li>
                                <li className="item"><a href="/Creation" id="aCreation">Création</a></li>
                                <li className="item"><a href="/Contact" id="aContact">Contact</a></li>
                            </ul>
                        </div>
                       
                    </Col>
                </Row>
            </div>
            <div id="menuDeroulant">
                <ul className="listeNav">
                    <li className="item"><a href="/" id="aAccueil">Accueil</a></li>
                    <li className="item"><a href="/Professionnel" id="aProfessionnel">Professionnel</a></li>
                    <li className="item"><a href="/Creation" id="aCreation">Création</a></li>
                    <li className="item"><a href="/Contact" id="aContact">Contact</a></li>
                </ul>
            </div>
        </div>
        );
    }
}

export default NavBar;