import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/footer.css';
import {Row, Col} from 'react-bootstrap';

import imgLinkedin from '../img/linkedin.png';
import imgInstagram from '../img/instagram.jpg';
import imgFacebook from '../img/facebook.png';
import imgGithub from '../img/github.png';

const Footer = () => {
    return (
        <div className="footer">
            <Row>
                <Col xs={12} md={5} lg={5}>
                    <table id="sectionConctact">
                        <tr className="titreFooter">
                            <h2 >INFORMATIONS DE CONTACT</h2>
                        </tr>
                        <tr className="textFooter">
                            <p>
                                N'hésitez pas à me contacter pour d'avantage d'information
                            </p>
                        </tr>
                        <tr className="caseFooter">
                            <h3>Adresse mail :</h3>
                            <a href="mailto:pheron.theric@gmail.com"><p>pheron.theric@gmail.com</p></a>
                        </tr>
                        <tr className="caseFooter">
                            <h3>Adresse mail d'école :</h3>
                            <a href="mailto:theric.pheron@edu.ece.fr"><p>theric.pheron@edu.ece.fr</p></a>
                        </tr>
                        <tr className="caseFooter">
                            <h3>Téléphone :</h3>
                            <a href="tel:+33623499804"><p>+3362399804</p></a>
                        </tr>
                    </ table>
                </Col>
                <Col xs={12} md={7} lg={7}>
                    <table id="sectionReseaux">
                        <tr className="titreFooter">
                            <h2 >RESEAUX SOCIAUX</h2>
                        </tr>
                        
                        <tr className="logoFooter">
                            <a href="https://www.linkedin.com/in/th%C3%A9ric-pheron-01b4541b7/"><img src={imgLinkedin} alt="logoLinkedin"/></a>
                            <a href="https://www.instagram.com/t.pheron/"><img src={imgInstagram} alt="logoInstagram"/></a>
                            <a href="https://www.facebook.com/theric.pheron"><img src={imgFacebook} alt="logoFacebook"/></a>
                            <a href="https://github.com/T-Pheron"><img src={imgGithub} alt="logoGitHub"/></a>
                        </tr>
                    </table>
                </Col>
            </Row>
            <Row>
                <p>© 2022 Create by Théric PHERON</p>
            </Row>
        </div>
    );
};

export default Footer;