import React from 'react';
import Home from './pages/Home';
import Error from './pages/Error';
import Professionnel from './pages/Professionnel.js';
import Footer from './elements/Footer';
import NavBar from './elements/NavBar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CentreVoile from './pages/CentreVoile';
import CentreAudioVisuel from './pages/CentreAudioVisuel';
import Creation from './pages/Creation';
import CreationPaysage from './pages/CreationPaysage';
import CreationVoyage from './pages/CreationVoyage';
import CreationPortrait from './pages/CreationPortrait';
import CreationNocturne from './pages/CreationNocturne';
import PaysageParis from './pages/creationPages/PaysageParis';
import PaysageCite from './pages/creationPages/PaysageCite';
import PaysageSoufriere from './pages/creationPages/PaysageSoufriere';
import VoyageTarnos from './pages/creationPages/VoyageTarnos';
import VoyageCroisiere from './pages/creationPages/VoyageCroisiere';
import VoyageBayonne from './pages/creationPages/VoyageBayonne';
import VoyageGuadeloupe from './pages/creationPages/VoyageGuadeloupe';
import VoyageAngers from './pages/creationPages/VoyageAngers';
import PortraitNature from './pages/creationPages/PortraitNature';
import PortraitCouleur from './pages/creationPages/PortraitCouleur';
import PortraitUrbain from './pages/creationPages/PortraitUrbain';
import PortraitPro from './pages/creationPages/PortraitPro';
import NocturneParviDuLouvre from './pages/creationPages/NocturneParviDuLouvre';
import NocturneIlluminationJardinDesPlantes from './pages/creationPages/NocturneIlluminationJardinDesPlantes';
import Contact from './pages/Contact';
import MesProjets from './pages/MesProjets';
import VoyageSkiBDS from './pages/creationPages/VoyageSkiBDS';
import NocturneSoireesECE from './pages/creationPages/NocturneSoireesECE';
import Admin from './pages/Admin';
import CreationVoyageTest from './pages/CreationVoyageTest';


const App = () => {

  return (
    <div>
      <NavBar/>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Admin" element={<Admin />} />
          <Route exact path="/Professionnel" element={<Professionnel />} />
          <Route exact path="/Professionnel/Mes-projets" element={<MesProjets />} />
          <Route exact path="/Professionnel/Centre-voile" element={<CentreVoile />} />
          <Route exact path="/Professionnel/Centre-audio" element={<CentreAudioVisuel />} />
          <Route exact path="/Contact" element={<Contact />} />
          <Route exact path="/Creation" element={<Creation />} />
          <Route exact path="/Creation-paysage" element={<CreationPaysage />} />
          <Route exact path="/Creation-paysage/Paris" element={<PaysageParis />} />
          <Route exact path="/Creation-paysage/Cite-des-sciences" element={<PaysageCite />} />
          <Route exact path="/Creation-paysage/Soufriere" element={<PaysageSoufriere />} />
          <Route exact path="/Creation-voyage" element={<CreationVoyage />} />
          <Route exact path="/Creation-voyage-test" element={<CreationVoyageTest />} />
          <Route exact path="/Creation-voyage/Tarnos" element={<VoyageTarnos />} />
          <Route exact path="/Creation-voyage/Bayonne" element={<VoyageBayonne />} />
          <Route exact path="/Creation-voyage/Croisiere-yatch-club-ece-2021" element={<VoyageCroisiere />} />
          <Route exact path="/Creation-voyage/Guadeloupe" element={<VoyageGuadeloupe />} />
          <Route exact path="/Creation-voyage/Angers" element={<VoyageAngers />} />
          <Route exact path="/Creation-voyage/Ski-BDS-ECE-2022" element={<VoyageSkiBDS />} />
          <Route exact path="/Creation-portrait" element={<CreationPortrait />} />
          <Route exact path="/Creation-portrait/Nature" element={<PortraitNature />} />
          <Route exact path="/Creation-portrait/Couleur" element={<PortraitCouleur />} />
          <Route exact path="/Creation-portrait/Professionnel" element={<PortraitPro />} />
          <Route exact path="/Creation-portrait/Urbain" element={<PortraitUrbain />} />
          <Route exact path="/Creation-nocturne" element={<CreationNocturne />} />
          <Route exact path="/Creation-nocturne/Parvi-du-louvre" element={<NocturneParviDuLouvre />} />
          <Route exact path="/Creation-nocturne/Illuminations-jardin-des-plantes" element={<NocturneIlluminationJardinDesPlantes />} />
          <Route exact path="/Creation-nocturne/Soiree-de-l-ECE-2022" element={<NocturneSoireesECE />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
      <Footer/>
    </div>
  );
};

export default App;
