import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_3809 from '../../img/creation/portrait/nature/IMG_3809.jpg';
import IMG_3972 from '../../img/creation/portrait/nature/IMG_3972.jpg';
import IMG_4443 from '../../img/creation/portrait/nature/IMG_4443.jpg';
import IMG_4774 from '../../img/creation/portrait/nature/IMG_4774.jpg';
import IMG_5641 from '../../img/creation/portrait/nature/IMG_5641.jpg';
import IMG_5650 from '../../img/creation/portrait/nature/IMG_5650.jpg';
import IMG_6008 from '../../img/creation/portrait/nature/IMG_6008.jpg';
import IMG_6412 from '../../img/creation/portrait/nature/IMG_6412.jpg';
import IMG_6468 from '../../img/creation/portrait/nature/IMG_6468.jpg';
import IMG_8632 from '../../img/creation/portrait/nature/IMG_8632.jpg';
import IMG_8781 from '../../img/creation/portrait/nature/IMG_8781.jpg';
import IMG_8800 from '../../img/creation/portrait/nature/IMG_8800.jpg';
import IMG_8886 from '../../img/creation/portrait/nature/IMG_8886.jpg';
import IMG_8948 from '../../img/creation/portrait/nature/IMG_8948.jpg';
import IMG_9482 from '../../img/creation/portrait/nature/IMG_9482.jpg';
import IMG_9512 from '../../img/creation/portrait/nature/IMG_9512.jpg';
import IMG_9769 from '../../img/creation/portrait/nature/IMG_9769.jpg';



const PortraitNature = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Nature</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_3809} alt="IMG_3809"/>
                            <img src={IMG_5650} alt="IMG_5650"/>
                            <img src={IMG_6008} alt="IMG_6008"/>
                            <img src={IMG_6412} alt="IMG_6412"/>
                            <img src={IMG_6468} alt="IMG_6468"/>
                            <img src={IMG_8800} alt="IMG_8800"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_4774} alt="IMG_4774"/>
                            <img src={IMG_8800} alt="IMG_8800"/>
                            <img src={IMG_8886} alt="IMG_8886"/>
                            <img src={IMG_9769} alt="IMG_9769"/>
                            <img src={IMG_8948} alt="IMG_8948"/>
                            <img src={IMG_9482} alt="IMG_9482"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_4443} alt="IMG_4443"/>
                            <img src={IMG_3972} alt="IMG_3972"/>
                            <img src={IMG_5641} alt="IMG_5641"/>
                            <img src={IMG_8632} alt="IMG_8632"/>
                            <img src={IMG_8781} alt="IMG_8781"/>
                            <img src={IMG_9512} alt="IMG_9512"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default PortraitNature;