import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';
import CarteCreation from '../elements/creation/CarteCreation';
import ReactPlayer from 'react-player';

import imgPaysage from '../img/creation/imgPaysage.jpg';
import imgPortrait from '../img/creation/imgPortrait.jpg';
import imgVoyage from '../img/creation/imgVoyage.jpg';
import imgNocturne from '../img/creation/imgNocturne.jpg';


const Creation = () => {

    let cartePaysage = <CarteCreation titre="Paysages" image={imgPaysage} couleur="vert" lien = "/Creation-paysage"/>
    let cartePortrait = <CarteCreation titre="Portraits" image={imgPortrait} couleur="voilet" lien = "/Creation-portrait"/>
    let carteVoyage = <CarteCreation titre="Voyages" image={imgVoyage} couleur="bleu" lien = "/Creation-voyage"/>
    let carteNocturne = <CarteCreation titre="Nocturnes" image={imgNocturne} couleur="jaune" lien = "/Creation-nocturne"/>

    return (
        <div id="creation">
            <div id="enteteCreation" className="header"></div>
            <div id="creationPresentation">
                <ReactPlayer 
                    id="videoPresentation"
                    url='videos/videoPresentation.mp4'
                    width='100%'
                    height='auto'
                    playing
                    muted
                    loop
                />
            </div>
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6}>
                        {cartePaysage}
                        {cartePortrait}
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                        {carteVoyage}
                        {carteNocturne}
                    </Col>
                </Row>
            </Container>
            
            
        </div>
    );
};

export default Creation;