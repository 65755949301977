import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_4305 from '../../img/creation/voyage/guadeloupe/IMG_4305.jpg';
import IMG_5110 from '../../img/creation/voyage/guadeloupe/IMG_5110.jpg';
import IMG_5166 from '../../img/creation/voyage/guadeloupe/IMG_5166.jpg';
import IMG_7934 from '../../img/creation/voyage/guadeloupe/IMG_7934.jpg';
import IMG_8792 from '../../img/creation/voyage/guadeloupe/IMG_8792.jpg';
import IMG_9226 from '../../img/creation/voyage/guadeloupe/IMG_9226.jpg';
import IMG_9280 from '../../img/creation/voyage/guadeloupe/IMG_9280.jpg';
import IMG_9811 from '../../img/creation/voyage/guadeloupe/IMG_9811.jpg';

const VoyageGuadeloupe = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Guadeloupe</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_4305} alt="IMG_4305"/>
                            <img src={IMG_5110} alt="IMG_5110"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_7934} alt="IMG_7934"/>
                            <img src={IMG_8792} alt="IMG_8792"/>
                            <img src={IMG_9811} alt="IMG_9811"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_9226} alt="IMG_9226"/>
                            <img src={IMG_5166} alt="IMG_5166"/>
                            <img src={IMG_9280} alt="IMG_9280"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default VoyageGuadeloupe;