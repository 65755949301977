import React from 'react';
import notFound  from '../img/404found.gif';

const Error = () => {
    return (
        <div id="error">
            <img src={notFound} alt="404NotFound"/>
        </div>
    );
};

export default Error;