import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/professionnel.css';
import {Container, Row, Col} from 'react-bootstrap';

import orange from '../../img/professionnel/orange.png';
import congesBTP from '../../img/professionnel/congesBTP.png';
import gendarmerie from '../../img/professionnel/gendarmerie.png';
import franceTV from '../../img/professionnel/franceTV.png';

const Experiences = () => {
    return (
        <div id="sectionExperience">
            <div id="titreExperiences">
                <h2>Expériences professionnelles</h2>
            </div>
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={3} className="experience" id="gendarmerie">
                        <img src={gendarmerie} alt="imageGendarmerie"/>
                        <h3>Gendarmerie</h3>
                        <h4>Stagiaire</h4>
                        <h5>Du 11/2014 au 11/2014</h5>
                        <p>Découverte du métier de gendarme</p>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="experience" id="franceTV">
                        <img src={franceTV} alt="imageFranceTV"/>
                        <h3>France télévision</h3>
                        <h4>Stagiaire</h4>
                        <h5>Du 05/2016 au 06/2016</h5>
                        <p> Réalisation des journaux télévisés
                        <br/> Assistant de l'ingénieur du son    
                        </p>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="experience" id="orangeFrance">
                        <img src={orange} alt="imageOrange"/>
                        <h3>Orange</h3>
                        <h4>Stagiaire</h4>
                        <h5>Du 01/2021 au 02/2021</h5>
                        <p>Test des procédures et de la sécurité des infrastructures
                        <br/> Assistant de l'ingénieur responsable du département SI Client
                        </p>
                    </Col>
                    <Col xs={12} md={6} lg={3} className="experience" id="congesBTP">
                        <img src={congesBTP} alt="imageCongesBTP"/>
                        <h3>Congés BTP</h3>
                        <h4>Stagiaire</h4>
                        <h5>Du 03/05/2022 au 26/08/2022</h5>
                        <p> Gestion du parc informatique
                        <br/> Maintenance du réseau informatique
                        <br/> Rédaction de documents sur différents aspects du réseau informatique
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Experiences;