import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_0648 from '../../img/creation/voyage/angers/IMG_0648.jpg';
import IMG_0684 from '../../img/creation/voyage/angers/IMG_0684.jpg';
import IMG_0699 from '../../img/creation/voyage/angers/IMG_0699-Edit.jpg';
import IMG_0742 from '../../img/creation/voyage/angers/IMG_0742-Edit.jpg';
import IMG_0870 from '../../img/creation/voyage/angers/IMG_0870.jpg';
import IMG_0984 from '../../img/creation/voyage/angers/IMG_0984.jpg';
import IMG_0986 from '../../img/creation/voyage/angers/IMG_0986.jpg';
import IMG_1034 from '../../img/creation/voyage/angers/IMG_1034.jpg';

const VoyageAngers = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Angers</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_0648} alt="IMG_4305"/>
                            <img src={IMG_0684} alt="IMG_5110"/>
                            <img src={IMG_0742} alt="IMG_8792"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_0699} alt="IMG_7934"/>
                            <img src={IMG_0870} alt="IMG_9811"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_0984} alt="IMG_9226"/>
                            <img src={IMG_0986} alt="IMG_5166"/>
                            <img src={IMG_1034} alt="IMG_9280"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default VoyageAngers;