import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/creation.css';
import { Container, Row} from 'react-bootstrap';
import CarteType from '../elements/creation/CarteType';


import imgNature from '../img/creation/portrait/nature/natureCover.jpg';
import imgCouleur from '../img/creation/portrait/couleur/couleurCover.jpg';
import imgPro from '../img/creation/portrait/pro/proCover.jpg';
import imgUrbain from '../img/creation/portrait/urbain/urbainCover.jpg';


const CreationPortrait = () => {
    
    let carteNature = <CarteType titre="Nature" image={imgNature} couleur="vert" lien = "/Creation-portrait/Nature"/>
    let carteCouleur = <CarteType titre="Couleurs" image={imgCouleur} couleur="rose" lien = "/Creation-portrait/Couleur"/>
    let cartePro = <CarteType titre="Professionnelle" image={imgPro} couleur="jaune" lien = "/Creation-portrait/Professionnel"/>
    let carteUrbain = <CarteType titre="Urbaine" image={imgUrbain} couleur="orange" lien = "/Creation-portrait/Urbain"/>

    return (
        <div id="creation">
            <div id="entete" className="header"></div>
            <div id="titre">
                <h2>Portraits</h2>
            </div>
            <Container>
                <div className="containerCarte">
                        {carteNature}
                        {carteCouleur}
                        {cartePro}
                        {carteUrbain}
                </div>
            </Container>
        </div>
    );
};

export default CreationPortrait;