import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/creation.css';
import { Container, Row} from 'react-bootstrap';
import CarteType from '../elements/creation/CarteType';


import imgParvi from '../img/creation/nocturne/parviDuLouvre/parviCover.jpg';
import imgIllumination from '../img/creation/nocturne/illuminationsJardinDesPlantes/illuminationCover.jpg';
import imgSoireeECE from '../img/creation/nocturne/soireesECE2022/soireesECECover.jpg';


const CreationNocturne = () => {
    
    let illuminationsJardinDesPlantes = <CarteType titre="Parvis du Louvre" image={imgParvi} couleur="orange" lien = "/Creation-nocturne/Parvi-du-louvre"/>
    let parviDuLouvre = <CarteType titre="Illuminations du Jardin des Plantes" image={imgIllumination} couleur="rose" lien = "/Creation-nocturne/Illuminations-jardin-des-plantes"/>
    let soireesECE2022 = <CarteType titre="Soirées de l'ECE 2022" image={imgSoireeECE} couleur="rose" lien = "/Creation-nocturne/Soiree-de-l-ECE-2022"/>

    return (
        <div id="creation">
            <div id="entete" className="header"></div>
            <div id="titre">
                <h2>Nocturnes</h2>
            </div>
            <Container>
                <div className="containerCarte">
                        {illuminationsJardinDesPlantes}
                        {parviDuLouvre}
                        {soireesECE2022}
                </div>
            </Container>
        </div>
    );
};

export default CreationNocturne;