import React, { useState } from 'react';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/admin.css';
import { ReactSession } from 'react-client-session';
import { useNavigate } from "react-router-dom";


function Admin() {


    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const login = () => {

        axios.post("http://localhost/serverSiteth/login/"+username+"/"+password, {
        }).then((response) => {
            console.log(response);
            console.log(response.data);
            if (response.data=="yes"){
                ReactSession.setStoreType("localStorage");
                ReactSession.set("username", "TPheron");
                console.log("session start");
                navigate("/admin");
                
            }
            else{
                const erreur = document.getElementById("erreur");
                erreur.innerText = "Utilisateur ou le mot de passe incorrecte";
            }
        });

    };

    if (ReactSession.get("username")=="TPheron"){
        return(
            <div>
                <div id="enteteAdmin" className="header"></div>
                <div id="titre">
                    <h2>Admin</h2>
                </div>
            </div>
        )

    }
    else{
        return (
            <div>
                <div id="enteteAdmin" className="header"></div>
                <div id="titre">
                    <h2>Admin</h2>
                </div>
                <div className="sectionConnection">
                    <input onChange={(e) => {
                        setUserName(e.target.value);
                    }} type="text" id="textUser" placeholder="Utilisateur"/><br/>
                    <input onChange={(e) => {
                        setPassword(e.target.value);
                    }} type="password" id="textMdp" placeholder="Mot de passe"/><br/>
                    <button onClick={login} id="submit">Connexion</button>
    
                    <h3 id="erreur"></h3>
                </div>
            </div>
        )
    }
}
export default Admin;


