import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_3354 from '../../img/creation/portrait/couleur/IMG_3354.jpg';    
import IMG_8964 from '../../img/creation/portrait/couleur/IMG_8964.jpg';
import IMG_8973 from '../../img/creation/portrait/couleur/IMG_8973.jpg';
import IMG_9020 from '../../img/creation/portrait/couleur/IMG_9020.jpg';



const PortraitNature = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Couleurs</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_3354} alt="IMG_3354"/>
                            <img src={IMG_8964} alt="IMG_8964"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_8973} alt="IMG_8973"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_9020} alt="IMG_9020"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default PortraitNature;