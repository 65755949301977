import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/home.css';
import { Row, Col} from 'react-bootstrap';

import fondProfessionel from '../img/accueil/fondProfessionel.png';
import fondCreation from '../img/accueil/fondCréation.png';
import fondAPropos from '../img/accueil/fondAPropos.jpg';


const Home = () => {

    return (
        
        <div className="body" >
            <div id="presentation">
                <h1>Théric PHERON</h1>
                <svg version="1.1" id="soulignePrenom" xmlns="http://www.w3.org/2000/svg" viewBox="0 6 64 54.8">
                    <line id="soulignePrenom" class="cls-1" x1="10" y1="10" x2="1200" y2="10"/>
                </svg>
                <p>Etudiant en ingénierie | Photographe | Vidéaste</p>
            </div>
            <div id="aPropos">
                <div id="textApropos">
                    <h2>EN BREF</h2>
                    <p>Je suis actuellement en deuxième année de master à l’École Centrale d’Électronique (ECE) Paris. Sérieux et toujours prêt pour en apprendre davantage sur des sujets qui me passionnent, j’ai choisi la majeure OCRES (Objets Connectés Réseaux et Services). Elle me permet de développer mes compétences dans le domaine des objets connectés mais aussi celui des réseaux. Des domaines qui m’attirent depuis le lycée et dans lesquels j’aimerais travailler.
                    <br/>Passionné par l’audio-visuel, je prends des photos et des vidéos pendant mon temps libre. Mes réalisations me permettent de garder en mémoire des moments importants et de créer des histoires qui me captivent.
                    <br/><br/>Tout cela est résumé sur mon site, que je vous laisse découvrir.
                    </p>
                </div>
                <img src={fondAPropos} alt="fond à propos"/>
            </div>
            {/* Visible pour les taille lg et md */}
            <div id="sectionProfessionel">
                <a href="Professionnel">
                    <Row>
                        <Col md={8} lg={8}>
                            <img src={fondProfessionel} alt="fondProfesionel"/>
                        </Col>
                        <Col md={4} lg={4} className="sectionNavigation" id="sectionNavigationProfessionel">
                            <h2>Professionnelle</h2>
                            
                            <svg clasName="flecheNavigation" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 24"><defs><linearGradient id="Dégradé_sans_nom_50" y1="12" x2="18" y2="12" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#00a7a9"/><stop offset="1" stop-color="#ff132c"/></linearGradient></defs>
                            <polyline points="1 23 17 12 1 1" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="url(#Dégradé_sans_nom_50)"/>
                            <polyline id="svgProfessionelNoir" points="1 23 17 12 1 1" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>
                        </Col>
                    </Row>
                </a>
            </div>
            <div id="sectionCreation">
                <a href="Creation">
                    <Row>
                        <Col md={8} lg={8}>
                            <img src={fondCreation} alt="fondCreation"/>
                        </Col>
                        <Col md={4} lg={4} className="sectionNavigation" id="sectionNavigationProfessionel">
                            <h2>Création</h2>

                            <svg id="flecheNavigation" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 24"><defs><linearGradient id="Dégradé_sans_nom_85" y1="12" x2="18" y2="12" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#508800"/><stop offset="1" stop-color="#ffd87c"/></linearGradient></defs>
                            <polyline points="1 23 17 12 1 1" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="url(#Dégradé_sans_nom_85)"/>
                            <polyline id="svgCreationNoir" points="1 23 17 12 1 1" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>
                        </Col>
                    </Row>
                </a>
            </div>
            {/* Visible pour les taille xs */}
            <div className="sectionNavigationxs" id="sectionProfesionxs">
                <a href="Professionnel">
                    <h2>Professionnelle</h2>
                </a>
            </div>
            <div className="sectionNavigationxs" id="sectionCreationxs">
                <a href="Creation">
                    <h2>Création</h2>
                </a>
            </div>
        </div>
    );
};

export default Home;