import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/mesProjets.css'
import SectionProjetGauche from '../elements/professionnel/SectionProjetGauche';
import SectionProjetDroite from '../elements/professionnel/SectionProjetDroite';
import SectionProjetDroiteLien from '../elements/professionnel/SectionProjetDroiteLien';

import arduino from '../img/professionnel/mesProjets/arduino.png'
import solidworks from '../img/professionnel/mesProjets/solidworks.png'
import appInverter from '../img/professionnel/mesProjets/appInverter.png'
import upStudio from '../img/professionnel/mesProjets/upStudio.png'
import regressi from '../img/professionnel/mesProjets/regressi.png'
import premierPro from '../img/professionnel/mesProjets/premierPro.png'
import gitHubDesktop from '../img/professionnel/mesProjets/gitHubDesktop.png'
import vsCode from '../img/professionnel/mesProjets/vsCode.png'
import codeBlocks from '../img/professionnel/mesProjets/codeBlocks.png'
import netBeans from '../img/professionnel/mesProjets/netBeans.png'
import wampServer from '../img/professionnel/mesProjets/wampServer.png'
import nodeRed from '../img/professionnel/mesProjets/nodeRed.png'
import cisco from '../img/professionnel/mesProjets/cisco.png'
import mongoDB from '../img/professionnel/mesProjets/mongoDB.png'
import postman from '../img/professionnel/mesProjets/postman.png'
import oracleDataBase from '../img/professionnel/mesProjets/oracleDataBase.png'
import openstack from '../img/professionnel/mesProjets/openstack.png';
import windows from '../img/professionnel/mesProjets/windows.png';
import linux from '../img/professionnel/mesProjets/linux.png';
import docker from '../img/professionnel/mesProjets/docker.png';
import powershell from '../img/professionnel/mesProjets/powerShell.png';
import bash from '../img/professionnel/mesProjets/bash.png';

import langageC from '../img/professionnel/mesProjets/langageC.png'
import langageJava from '../img/professionnel/mesProjets/langageJava.png'
import langageJavaFX from '../img/professionnel/mesProjets/langageJavaFX.png'
import langageCSS from '../img/professionnel/mesProjets/langageCSS.png'
import langageHtml from '../img/professionnel/mesProjets/langageHtml.png'
import langageJavaScript from '../img/professionnel/mesProjets/langageJavaScript.png'
import langageJQuery from '../img/professionnel/mesProjets/langageJQuery.png'
import langagePHP from '../img/professionnel/mesProjets/langagePHP.png'
import langageSQL from '../img/professionnel/mesProjets/langageSQL.png'
import langageReact from '../img/professionnel/mesProjets/langageReact.png'
import langageBootstrap from '../img/professionnel/mesProjets/langageBootstrap.png'
import langageAxios from '../img/professionnel/mesProjets/langageAxios.png'

import imgAlpha from '../img/professionnel/mesProjets/imgAlpha.png'
import imgD2S from '../img/professionnel/mesProjets/imgD2S.png'
import imgTipe from '../img/professionnel/mesProjets/imgTipe.png'
import imgScrabble from '../img/professionnel/mesProjets/imgScrabble.png'
import imgBatailleNavale from '../img/professionnel/mesProjets/imgBatailleNavale.png'
import imgEceMarketPlace from '../img/professionnel/mesProjets/imgEceMarketPlace.png'
import imgChauffageConnecte from '../img/professionnel/mesProjets/imgChauffageConnecte.png'
import imgMissionRedNet from '../img/professionnel/mesProjets/imgMissionRedNet.png'
import imgTableauBordDomotique from '../img/professionnel/mesProjets/imgTableauBordDomotique.png'
import imgTraditour from '../img/professionnel/mesProjets/imgTraditour.png'
import imgSOSProjet from '../img/professionnel/mesProjets/imgSOSProjet.png'

const MesProjets = () => {

    let SOSProet = <SectionProjetGauche
    titreProjet = "Network Operating System"
    titreMatiere = "Réseaux TCP/IP et Systèmes exploitations Linux et Windows"
    titreAnnee = "2022 - Troisième année du cycle d'ingénieur ECE Paris"
    titreCouleur = "rouge"
    textResume = "Ce projet avait pour objectif de nous permettre de maitriser la gestion des systèmes d’exploitation réseau (Windows Server et Linux Debian) ainsi que leurs services réseau déployés dans un environnement réseau. Le déploiement du réseau et la gestion étaient réalisés sur l’outil Open Stack."
    textCompetence1 = "- Mise en place des fonctionnalités et services des systèmes Linux et Windows"
    textCompetence2 = "- Gestion des systèmes d'exploitation Linux et Windows"
    textCompetence3 = "- Concevoir et mettre en place un réseau complexe"
    titrelogiciel1 = "Open Stack"
    imgLogiciel1 = {openstack}
    titrelogiciel2 = "Windows Server"
    imgLogiciel2 = {windows}
    titrelogiciel3 = "Linux"
    imgLogiciel3 = {linux}
    titrelogiciel4 = "Docker"
    imgLogiciel4 = {docker}
    titrelogiciel5 = "PowerShell"
    imgLogiciel5 = {powershell}
    titrelogiciel6 = "Bash"
    imgLogiciel6 = {bash}
    imgProjet = {imgSOSProjet}
    />

    let siteTraditour = <SectionProjetDroiteLien
    titreProjet = "Site d'une compétition de voile"
    titreMatiere = "Programation web - Langages Web"
    titreAnnee = "2022 - Projet personnel"
    titreCouleur = "jaune"
    textResume = "Ce projet personnel avait pour but de mettre en avant une discipline que je pratique, la voile. J’ai créé un site web qui permettait au public de découvrir, suivre et comprendre la compétition ‘‘Traditour’’. C'est le tour de la Guadeloupe en voile traditionnelle. Les compétiteurs avaient accès à un espace personnel avec les informations qui les concernent comme la météo, les documents de course, etc. L’organisation avait un espace afin de publier les résultats, les photos, les vidéos et les différentes informations concernants la course. "
    lien = "https://traditour.thericpheron.fr/accueil.php"
    textCompetence1 = "- Gestion de données sur une base de données"
    textCompetence2 = "- Actualisation automatique d'un site en fonction des horaires et des jours"
    textCompetence3 = "- Gestion du téléchargement de document"
    titrelogiciel1 = "Visual Code Studio"
    imgLogiciel1 = {vsCode}
    titrelogiciel2 = "Langage HTML"
    imgLogiciel2 = {langageHtml}
    titrelogiciel3 = "langage CSS"
    imgLogiciel3 = {langageCSS}
    titrelogiciel4 = "Langage JavaScript"
    imgLogiciel4 = {langageJavaScript}
    titrelogiciel5 = "Bootstrap"
    imgLogiciel5 = {langageBootstrap}
    titrelogiciel6 = "Langage PHP"
    imgLogiciel6 = {langagePHP}
    titrelogiciel7 = "Langage SQL"
    imgLogiciel7 = {langageSQL}
    titrelogiciel8 = "Oracle Database"
    imgLogiciel8 = {oracleDataBase}
    imgProjet = {imgTraditour}
    />

    let tableauBordDomotique = <SectionProjetGauche
    titreProjet = "Tableau de bord Domotique - SmartThings"
    titreMatiere = "Programation orienté objet - Langage Java"
    titreAnnee = "2021 - Deuxième année du cycle d'ingénieur ECE Paris"
    titreCouleur = "bleu"
    textResume = "Le but du projet était de créer un tableau de bord responsive qui communique avec des API. Mon coéquipier et moi avons décidé de créer un tableau de bord d’une maison connecté. A l’aide de l’API de SmartThings, le tableau bord pouvait piloter tous les appareils de mon appartement et pouvait déclencher des scènes sous les instructions de l’utilisateur."
    textCompetence1 = "- Développer un site avec une bibliothéque JavaScript"
    textCompetence2 = "- Site Web responsive"
    textCompetence3 = "- Création et communication avec une API"
    titrelogiciel1 = "Visual Code Studio"
    imgLogiciel1 = {vsCode}
    titrelogiciel2 = "Mongo DB"
    imgLogiciel2 = {mongoDB}
    titrelogiciel3 = "Postman"
    imgLogiciel3 = {postman}
    titrelogiciel4 = "Langage React"
    imgLogiciel4 = {langageReact}
    titrelogiciel5 = "Langage HTML"
    imgLogiciel5 = {langageHtml}
    titrelogiciel6 = "langage CSS"
    imgLogiciel6 = {langageCSS}
    titrelogiciel7 = "Langage JavaScript"
    imgLogiciel7 = {langageJavaScript}
    titrelogiciel8 = "Bootstrap"
    imgLogiciel8 = {langageBootstrap}
    titrelogiciel9 = "Client HTTP Axios"
    imgLogiciel9 = {langageAxios}
    imgProjet = {imgTableauBordDomotique}
    />

    let missionRedNet = <SectionProjetDroite
        titreProjet = "Mission REDNET"
        titreMatiere = "Réseaux IP et Routage - Cisco Packet Tracer"
        titreAnnee = "2021 - Deuxième année du cycle d'ingénieur ECE Paris"
        titreCouleur = "rouge"
        textResume = "Ce projet qui été sous format de TP dirigé nous a permis de comprendre et pratiquer les compétences qu’on avait acquis en cours de réseaux IP et routage. Le but du projet était la mise en place d’un réseau (sur mars). On a pu pratiquer sur des notions comme la connexion avec les protocoles Telnet et SSH, le découpage de réseau en VLAN, la configuration de serveur DHCP et DNS mais aussi sur les notions de routage statique et dynamique"
        textCompetence1 = "- Structurer un réseaux IP"
        textCompetence2 = "- Routage de paquet sur un réseau"
        textCompetence3 = "- Protocoles de connexion"
        titrelogiciel1 = "Cisco Packet Tracer"
        imgLogiciel1 = {cisco}
        imgProjet = {imgMissionRedNet}
        />


    let chauffageConnecte = <SectionProjetGauche
    titreProjet = "Chauffage connecté"
    titreMatiere = "IoT - Langage C"
    titreAnnee = "2021 - Deuxième année du cycle d'ingénieur ECE Paris"
    titreCouleur = "bleu"
    textResume = "Mon équipe et moi avons réalisé un chauffage connecté. A l’aide d’un ESP8866, un circuit intégré avec microcontrôleur et connexion Wi-Fi, ainsi qu’un capteur de température (DHT22) nous avons simulé l’allumage, l’extinction et le contrôle à distance du chauffage. A l’aide de Node-Red nous avons afficher les informations de notre chauffage sur un écran distant contrôlé par un autre circuit intégré."
    textCompetence1 = "- Développer un système domotique"
    textCompetence2 = "- Programmation low-code avec un éditeur de flux"
    titrelogiciel1 = "Arduino"
    imgLogiciel1 = {arduino}
    titrelogiciel2 = "Node-Red"
    imgLogiciel2 = {nodeRed}
    titrelogiciel3 = "Langage C"
    imgLogiciel3 = {langageC}
    imgProjet = {imgChauffageConnecte}
    />

    let eceMarketPlace = <SectionProjetDroite
        titreProjet = "ECE Market Place"
        titreMatiere = "Web Dynamique - HTML"
        titreAnnee = "2021 - Première année du cycle d'ingénieur ECE Paris"
        titreCouleur = "bleu"
        textResume = "Ce projet avait pour objectif de développer nos compétences en nous plongeant pendant une semaine dans la programmation en web dynamique. Le site qu’on devait faire été une marketplace, du frontend au backend. Nous avons créé un serveur virtuel, fait la base de données, géré la communication entre le backend et le frontend, géré l’authentification d’un utilisateur, la création d’un utilisateur, l’affichage d’un article, etc."
        textCompetence1 = "- La gestion de base de données avec les modèles entité association et relationnel"
        textCompetence2 = "- Programmation pour le développement Web"
        textCompetence3 = "- Programmation de scripts"
        titrelogiciel1 = "Visual Code Studio"
        imgLogiciel1 = {vsCode}
        titrelogiciel2 = "GitHub Desktop"
        imgLogiciel2 = {gitHubDesktop}
        titrelogiciel3 = "Wamp Server"
        imgLogiciel3 = {wampServer}
        titrelogiciel4 = "Langage HTML"
        imgLogiciel4 = {langageHtml}
        titrelogiciel5 = "Langage CSS"
        imgLogiciel5 = {langageCSS}
        titrelogiciel6 = "Langage JavaScript"
        imgLogiciel6 = {langageJavaScript}
        titrelogiciel7 = "Langage jQuery"
        imgLogiciel7 = {langageJQuery}
        titrelogiciel8 = "Langage PHP"
        imgLogiciel8 = {langagePHP}
        titrelogiciel9 = "Langage SQL"
        imgLogiciel9 = {langageSQL}
        imgProjet = {imgEceMarketPlace}
        />

    let batailleNavale = <SectionProjetGauche
    titreProjet = "Bataille Navale"
    titreMatiere = "Programmation  orienté objet - Langage Java"
    titreAnnee = "2021 - Première année du cycle d'ingénieur ECE Paris"
    titreCouleur = "bleu"
    textResume = "Dans ce projet mon équipier et moi avons développés un jeu en Java. Le jeu était une bataille navale où on pouvait jouer à 2 joueurs mais aussi tout seul face à une IA qui avait différents niveaux. Le jeu comportait la possibilité de sauvegarder jusqu’à 10 parties et tout cela était afficher sur une interface graphique codé en java FX."
    textCompetence1 = "- Le développement d'un jeu graphique 100% avec des lignes de code"
    textCompetence2 = "- Faire un logiciel avec un motif d'architecture modèle-vue-contrôleur"
    textCompetence3 = "- Créer une IA (qui ne connait pas le jeu de l'autre) avec différents niveaux de difficulté"
    titrelogiciel1 = "Net Beans"
    imgLogiciel1 = {netBeans}
    titrelogiciel2 = "GitHub Desktop"
    imgLogiciel2 = {gitHubDesktop}
    titrelogiciel3 = "Langage Java"
    imgLogiciel3 = {langageJava}
    titrelogiciel4 = "Langage Java FX"
    imgLogiciel4 = {langageJavaFX}
    imgProjet = {imgBatailleNavale}
    />

    let scrabble = <SectionProjetDroite
        titreProjet = "Scrabble®"
        titreMatiere = "Programmation de bas niveau - Langage C"
        titreAnnee = "2020 - Première année du cycle d'ingénieur ECE Paris"
        titreCouleur = "vert"
        textResume = "L’objectif de ce projet en informatique été d’évaluer nos compétences dans la programmation avec le langage C. Il nous a été demandé de programmer un jeu de scrabble avec la plupart des règles originales du jeux. Notre programme final permettait de faire une partie complète de jeux de 2 à 4 joueurs avec un plateau en ligne de commande."
        textCompetence1 = "- Programmation de langage de bas niveau"
        textCompetence2 = "- Organiser la création d'un jeu en langage bas niveau"
        textCompetence3 = "- Travaille en équipe à distance"
        titrelogiciel1 = "Code Blocks"
        imgLogiciel1 = {codeBlocks}
        titrelogiciel2 = "GitHub Desktop"
        imgLogiciel2 = {gitHubDesktop}
        titrelogiciel3 = "Langage C"
        imgLogiciel3 = {langageC}
        imgProjet = {imgScrabble}
        />


    let tipe = <SectionProjetGauche
    titreProjet = "L’énergie absorbée par l’eau lors d’un plongeon"
    titreMatiere = "Physique - TIPE"
    titreAnnee = "2020 - PC"
    titreCouleur = "rouge"
    textResume = "Mon équipe et moi dans notre étude, on s’est intéressé aux pourcentages d’énergie qui est absorbée par l’eau dans différentes conditions, en faisant varier la hauteur, la viscosité, la masse du projectile, la surface de contact du projectile avec l’eau, afin de relier cette variation à l’énergie que reçoit un projectile qui rentre dans l’eau. Tout cela afin d’observer l’influence de ces différents paramètres tout en ayant un ordre de grandeur de l’énergie que transfère l’eau."
    textCompetence1 = "- La mise en place d'un protocole de test scientifique"
    textCompetence2 = "- Le travail de précision et de répétition identique afin d'avoir des statistiques fiables"
    textCompetence3 = "- La mesure de la vitesse, des distances à l'aide logiciel informatique"
    titrelogiciel1 = "Regressi"
    imgLogiciel1 = {regressi}
    titrelogiciel2 = "Premiere Pro"
    imgLogiciel2 = {premierPro}
    imgProjet = {imgTipe}
    />

    let D2S = <SectionProjetDroite
        titreProjet = "D2S - Drone de sauvetage"
        titreMatiere = "SI - PID"
        titreAnnee = "2018 - Terminal S-SI"
        titreCouleur = "jaune"
        textResume = "Notre objectif dans ce projet était de diminuer le nombre de noyade sur les côtes françaises. C’est pour cela que nous avions comme problématique ‘‘Comment améliorer la vitesse d’intervention d’une équipe de sauveteur en mer’’. Nous avons réussi avec mon équipe à faire un drone et un système de largage de la bouée (imprimé en 3D) qui fonctionne."
        textCompetence1 = "- Test de résistance numérique et d'écoulement de fluide sur des éléments modélisés en 3D"
        textCompetence2 = "- Paramétrer une impression 3D afin d'avoir le résultat souhaité"
        textCompetence3 = ""
        titrelogiciel1 = "Arduino"
        imgLogiciel1 = {arduino}
        titrelogiciel2 = "Up Studio"
        imgLogiciel2 = {upStudio}
        titrelogiciel3 = "Langage C"
        imgLogiciel3 = {langageC}
        imgProjet = {imgD2S}
        />


    let alpha = <SectionProjetGauche
        titreProjet = "Alpha - Rollers motorisés"
        titreMatiere = "SI - TPE"
        titreAnnee = "2017 - Premiers S-SI"
        titreCouleur = "rouge"
        textResume = "Dans ce projet mon équipe et moi avons développer des rollers motorisés pendant environ un semestre en terminal. Notre problématique pour ce projet était ''Comment se déplacer en faisant le moins d'effort, tout en gardant les avantages des chaussures ?''. Par manque de temps nous n'avons pas pu réaliser un prototype mais tous nos circuits électroniques et logiciels fonctionnaient correctement."
        textCompetence1 = "- Développer un système électronique fonctionnel et cohérent"
        textCompetence2 = "- Permettre la communication sans fil entre deux composants"
        textCompetence3 = ""
        titrelogiciel1 = "Arduino"
        imgLogiciel1 = {arduino}
        titrelogiciel2 = "Solid Works"
        imgLogiciel2 = {solidworks}
        titrelogiciel3 = "App Inverter"
        imgLogiciel3 = {appInverter}
        titrelogiciel4 = "Langage C"
        imgLogiciel4 = {langageC}
        imgProjet = {imgAlpha}
        />
    
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreMesProjets">
                    <h2>Mes Projets</h2>
            </div>
            {SOSProet}
            {siteTraditour}
            {tableauBordDomotique}
            {missionRedNet}
            {chauffageConnecte}
            {eceMarketPlace}
            {batailleNavale}
            {scrabble} 
            {tipe} 
            {D2S} 
            {alpha} 
        </div>
        
    );
};

export default MesProjets;