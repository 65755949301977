import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/contact.css'
import {Row, Col} from 'react-bootstrap';



import imgLinkedin from '../img/linkedin.png';
import imgInstagram from '../img/instagram.jpg';
import imgFacebook from '../img/facebook.png';
import imgGithub from '../img/github.png';


const Contact = () => {
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");


    const isEmail = () =>{
        let mail = document.getElementById("email");
        let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (email.match(regex)){
            mail.style.backgroundColor = "white";
            return true;
        }
        else{
            mail.style.animationName = "dongle";
            mail.style.animationDuration = "500ms";
            mail.style.backgroundColor = "rgb(255, 82, 82)";
            return false;
        }
    }

    const failMessage = () =>{
        let formMess = document.querySelector(".formMessage");
        formMess.innerHTML= "Merci de remplir tous les champs obligatoires *";
        formMess.style.color = "rgb(255, 82, 82)";

        if (name==false){
            document.getElementById('name').classList.add('error');
            document.getElementById('name').style.animationName = "dongle";
            document.getElementById('name').style.animationDuration = "500ms";    
        }
        else{
            document.getElementById('name').classList.remove('error');
        }

        if (email==false){
            document.getElementById('email').classList.add('error');
            document.getElementById('email').style.animationName = "dongle";
            document.getElementById('email').style.animationDuration = "500ms";
        }
        else{
            document.getElementById('email').classList.remove('error');
        }

        if (message==false){
            document.getElementById('message').classList.add('error');
            document.getElementById('message').style.animationName = "dongle";
            document.getElementById('message').style.animationDuration = "500ms";
        }
        else{
            document.getElementById('message').classList.remove('error');
        }
    }


    const successMessage = () => {
        let formMess = document.querySelector(".formMessage");
        formMess.innerHTML= "Votre message a bien été envoyé. Je vous recontacte au plus vite."
        formMess.style.color = "rgb(96, 250, 117)";

        document.getElementById('name').classList.remove('error');
        document.getElementById('email').classList.remove('error');
        document.getElementById('message').classList.remove('error');
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isEmail() && name &&  message){
            sendFeedback("template_j66eb9x", {
                name,
                company,
                phone,
                email,
                message,
                });
        }
        else{
            failMessage()
        }
        
    };

    const sendFeedback = (templateId, variables) => {

        window.emailjs
        .send("service_olnhngh", templateId, variables)
        .then((res) => {
            successMessage();
            setName("");
            setCompany("");
            setPhone("");
            setEmail("");
            setMessage("");
        })
        .catch(
            (err) =>
            document.querySelector('.formMessage').innerHTML =
                "Une erreur s'est produite, veuillez réessayer.")
            document.querySelector('.formMessage').formMess.style.color = "rgb(255, 82, 82)";

    };


    return (
        <div>
            <Row id="rowRigth">
                <div id="enteteContact" className="header"></div>
                <div id="titreContact">
                    <h2>Contact</h2>
                </div>
                <Col xs={12} md={12} lg={5} id="sectionFormulaire">
                    <h2 className="titreSectionContact">Formulaire de contact</h2>
                    <form className="contactForm">
                        <div className="formContent">
                            <h3>Nom et Prénom : * </h3>
                            <input
                            type="text"
                            id="name"
                            name="name"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            autoComplete="off"
                            />
                            <h3>Société : </h3>
                            <input
                            type="text"
                            id="company"
                            name="company"
                            onChange={(e) => setCompany(e.target.value)}
                            value={company}
                            />
                            <h3>Téléphone : </h3>
                            <input
                            type="text"
                            id="phone"
                            name="phone"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            />
                            <div className="emailContent">
                            <h3>Email : * </h3>
                            <input
                                type="mail"
                                id="email"
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                autoComplete="off"
                            />
                            </div>
                            <h3>Message : * </h3>
                            <textarea
                            id="message"
                            name="message"
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                            />
                        </div>
                        <input
                            className="button"
                            type="button"
                            value="Envoyer"
                            onClick={handleSubmit}
                        />
                        <div className="formMessage"></div>
                    </form>
                </Col>
                <Col xs={12} md={12} lg={4} id="sectionCoordones">
                    <table id="sectionConctact">
                        <h2 className="titreSectionContact">D'autre moyens de me contacter</h2>
                        <tr className="ligneFormulaire">
                            <h3>Adresse mail :</h3>
                            <a href="mailto:pheron.theric@gmail.com"><p>pheron.theric@gmail.com</p></a>
                        </tr>
                        <tr className="ligneFormulaire">
                            <h3>Adresse mail école :</h3>
                            <a href="mailto:theric.pheron@edu.ece.fr"><p>theric.pheron@edu.ece.fr</p></a>
                        </tr>
                        <tr className="ligneFormulaire">
                            <h3>Téléphone :</h3>
                            <a href="tel:+33623499804"><p>+3362399804</p></a>
                        </tr>
                    </ table>
                </Col>
                <Col xs={12} md={12} lg={3} id="sectionReseauxContact">
                    <table>
                        <tr>
                            <h2 className="titreSectionContact">Mes réseaux sociaux</h2>
                        </tr>
                        
                        <tr className="logoContact">
                            <a href="https://www.linkedin.com/in/th%C3%A9ric-pheron-01b4541b7/"><img src={imgLinkedin} alt="logoLinkedin"/></a>
                            <a href="https://www.instagram.com/t.pheron/"><img src={imgInstagram} alt="logoInstagram"/></a>
                            <a href="https://www.facebook.com/theric.pheron"><img src={imgFacebook} alt="logoFacebook"/></a>
                            <a href="https://github.com/T-Pheron"><img src={imgGithub} alt="logoGitHub"/></a>
                        </tr>
                    </table>
                </Col>
            </Row>
        </div>
    );
};

export default Contact;