import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/mesProjets.css';
import {Row, Col, Container} from 'react-bootstrap';


export default class SectionProjetDroiteLien extends React.Component {
    
    constructor(props){
        super(props);

        this.state = {
            titreProjet : props.titreProjet,
            titreMatiere : props.titreMatiere,
            titreAnnee : props.titreAnnee,
            titreCouleur : props.titreCouleur,
            textResume : props.textResume,
            lien : props.lien,
            textCompetence1 : props.textCompetence1,
            textCompetence2 : props.textCompetence2,
            textCompetence3 : props.textCompetence3,
            titrelogiciel1 : props.titrelogiciel1,
            imgLogiciel1 : props.imgLogiciel1,
            titrelogiciel2 : props.titrelogiciel2,
            imgLogiciel2 : props.imgLogiciel2,
            titrelogiciel3 : props.titrelogiciel3,
            imgLogiciel3 : props.imgLogiciel3,
            titrelogiciel4 : props.titrelogiciel4,
            imgLogiciel4 : props.imgLogiciel4,
            titrelogiciel5: props.titrelogiciel5,
            imgLogiciel5 : props.imgLogiciel5,
            titrelogiciel6 : props.titrelogiciel6,
            imgLogiciel6 : props.imgLogiciel6,
            titrelogiciel7 : props.titrelogiciel7,
            imgLogiciel7 : props.imgLogiciel7,
            titrelogiciel8 : props.titrelogiciel8,
            imgLogiciel8 : props.imgLogiciel8,
            titrelogiciel9 : props.titrelogiciel9,
            imgLogiciel9 : props.imgLogiciel9,
            imgProjet : props.imgProjet
        }
    }

    render() {
        return (
            <Row id="rowSectionProjet">
                <Col xs={12} md={12} lg={4} className="sectionPhotoProjet" id="photoProjetHaut" style={{backgroundImage: `url(${this.state.imgProjet})`}}></Col>
                <Col xs={12} md={12} lg={8} className="sectionTextProjet" >
                    <Container>
                        <h2 id={this.state.titreCouleur}>{this.state.titreProjet}</h2>
                        <h3>{this.state.titreMatiere}</h3>
                        <h4>{this.state.titreAnnee}</h4>
                        <p>{this.state.textResume}</p>
                        <p style={{paddingTop: "2em"}}>Lien vers une sauvegarde du site : <a href = {this.state.lien} target="_blank" ><p className="pLien">{this.state.lien}</p> </a> </p>
                        <Row className="ligneCompetence">
                            <Col xs={12} md={12} lg={7} className="colCompetence">
                                <h3>Compétences développées :</h3>
                                <p>{this.state.textCompetence1}</p>
                                <p>{this.state.textCompetence2}</p>
                                <p>{this.state.textCompetence3}</p>
                            </Col>
                            <Col xs={12} md={12} lg={5} className="colLogiciels">
                                <h3>Logiciels et langages utilisés :</h3>
                                <div className="ligneImgCompetence">
                                    <div className="imgCompetence">
                                        <img src={this.state.imgLogiciel1} alt={this.state.titrelogiciel1}/>
                                        <h3>{this.state.titrelogiciel1}</h3>
                                    </div>
                                    <div className="imgCompetence">
                                        <img src={this.state.imgLogiciel2} alt={this.state.titrelogiciel2}/>
                                        <h3>{this.state.titrelogiciel2}</h3>
                                    </div>
                                    <div className="imgCompetence">
                                        <img src={this.state.imgLogiciel3} alt={this.state.titrelogiciel3}/>
                                        <h3>{this.state.titrelogiciel3}</h3>
                                    </div>
                                    <div className="imgCompetence">
                                        <img src={this.state.imgLogiciel4} alt={this.state.titrelogiciel4}/>
                                        <h3>{this.state.titrelogiciel4}</h3>
                                    </div>
                                    <div className="imgCompetence">
                                        <img src={this.state.imgLogiciel5} alt={this.state.titrelogiciel5}/>
                                        <h3>{this.state.titrelogiciel5}</h3>
                                    </div>
                                    <div className="imgCompetence">
                                        <img src={this.state.imgLogiciel6} alt={this.state.titrelogiciel6}/>
                                        <h3>{this.state.titrelogiciel6}</h3>
                                    </div>
                                    <div className="imgCompetence">
                                        <img src={this.state.imgLogiciel7} alt={this.state.titrelogiciel7}/>
                                        <h3>{this.state.titrelogiciel7}</h3>
                                    </div>
                                    <div className="imgCompetence">
                                        <img src={this.state.imgLogiciel8} alt={this.state.titrelogiciel8}/>
                                        <h3>{this.state.titrelogiciel8}</h3>
                                    </div>
                                    <div className="imgCompetence">
                                        <img src={this.state.imgLogiciel9} alt={this.state.titrelogiciel9}/>
                                        <h3>{this.state.titrelogiciel9}</h3>
                                    </div>
                                </div>
                                    
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col xs={12} md={12} lg={4} className="sectionPhotoProjet" id="photoProjetDroit" style={{backgroundImage: `url(${this.state.imgProjet})`}}></Col>
            </Row>
        );
    }
    
};