import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_1610 from '../../img/creation/paysage/paris/IMG_1610.jpg';
import IMG_1662 from '../../img/creation/paysage/paris/IMG_1662.jpg';
import IMG_1676 from '../../img/creation/paysage/paris/IMG_1676.jpg';
import IMG_1694 from '../../img/creation/paysage/paris/IMG_1694.jpg';
import IMG_2098 from '../../img/creation/paysage/paris/IMG_2098.jpg';
import IMG_2106 from '../../img/creation/paysage/paris/IMG_2106.jpg';
import IMG_7802 from '../../img/creation/paysage/paris/IMG_7802.jpg';
import IMG_7806 from '../../img/creation/paysage/paris/IMG_7806.jpg';
import IMG_7811 from '../../img/creation/paysage/paris/IMG_7811.jpg';
import IMG_8812 from '../../img/creation/paysage/paris/IMG_8812.jpg';
import IMG_8912 from '../../img/creation/paysage/paris/IMG_8912.jpg';
import IMG_8973 from '../../img/creation/paysage/paris/IMG_8973.jpg';
import IMG_8991 from '../../img/creation/paysage/paris/IMG_8991.jpg';

const PaysageParis = () => {
    return (
        <div> 
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Paris</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_1662} alt="IMG_1662"/>
                            <img src={IMG_1610} alt="IMG_1610"/>
                            <img src={IMG_2098} alt="IMG_2098"/>
                            <img src={IMG_8812} alt="IMG_8812"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_1694} alt="IMG_1694"/>
                            <img src={IMG_1676} alt="IMG_1676"/>
                            <img src={IMG_2106} alt="IMG_2106"/>
                            <img src={IMG_8973} alt="IMG_8973"/>
                            <img src={IMG_8991} alt="IMG_8991"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_7802} alt="IMG_7802"/>
                            <img src={IMG_7806} alt="IMG_7806"/>
                            <img src={IMG_7811} alt="IMG_7811"/>
                            <img src={IMG_8912} alt="IMG_8912"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default PaysageParis;