import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_8140 from '../../img/creation/paysage/soufriere/IMG_8140.jpg';
import IMG_8182 from '../../img/creation/paysage/soufriere/IMG_8182.jpg';
import IMG_8187 from '../../img/creation/paysage/soufriere/IMG_8187.jpg';
import IMG_8488 from '../../img/creation/paysage/soufriere/IMG_8488.jpg';
import IMG_8522 from '../../img/creation/paysage/soufriere/IMG_8522.jpg';
import IMG_8557 from '../../img/creation/paysage/soufriere/IMG_8557.jpg';

const PaysageSoufriere = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Soufrière</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_8182} alt="IMG_8182"/>
                            <img src={IMG_8140} alt="IMG_8140"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_8187} alt="IMG_8187"/>
                            <img src={IMG_8522} alt="IMG_8522"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_8557} alt="IMG_8557"/>
                            <img src={IMG_8488} alt="IMG_8488"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default PaysageSoufriere;