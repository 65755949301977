import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/professionnel.css';
import {Container,Row, Col} from 'react-bootstrap';

import photoPP from '../../img/professionnel/photoPP.jpg';
import Competence from '../professionnel/Competence';

const  ResumerPro = () => {
    return (
        <div id="sectionResumer">
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={6} id="colonneInformations">
                        <Row>
                            <Col xs={12} md={6} lg={6} id="photoPP">
                                <img src={photoPP} alt="photoProfil"/>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <Row className="informations"><p><strong>Prénom :</strong> Théric</p></Row>
                                <Row className="informations"><p><strong>Nom :</strong> Pheron</p></Row>
                                <Row className="informations"><p><strong>Email:</strong><a href="mailto:pheron.theric@gmail.com"> pheron.theric@gmail.com</a></p></Row>
                                <Row className="informations"><p><strong>Tél :</strong> <a href="tel:+33623499804">+3362399804</a></p></Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={12} lg={6} id="textResumer">
                        <h2>En bref</h2>
                        <p>
                        Je suis à la recherche d'un stage de fin d'étude de 6 mois à partir de février 2023 en tant qu’ingénieur systèmes et réseaux ou administrateur système et réseau. 
                        <br/><br/>Actuellement en deuxième année de master à l’École Centrale d’Électronique (ECE) Paris. J’ai choisi la majeure Objets Connectés Réseaux et Services (OCRES). J’ai pu développer mes compétences dans des domaines comme l’IoT (Internet of Things), le développement Web, les réseaux IP et le routage, ainsi qu’acquérir des connaissances utiles via des cours d'UI/UX Design, de gamification et de finance.
                        <br/><br/>Réaliser un stage dans les domaines des réseaux me permettrait de développer mes connaissances dans ce domaine afin que par la suite je puisse intégrer le monde du travail dans les meilleurs conditions.
                        </p>
                    </Col>
                </Row>
                <Row id="zoneCompetence">
                    {<Competence/>}
                </Row>
                
                
            </Container>
        </div>
    );
};

export default ResumerPro;