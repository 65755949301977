import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_20211101_112554 from '../../img/creation/voyage/croisiereYatchClubECE2021/20211101_112554.jpg';
import IMG_20211104_170633 from '../../img/creation/voyage/croisiereYatchClubECE2021/20211104_170633.jpg';
import IMG_20211104_170634 from '../../img/creation/voyage/croisiereYatchClubECE2021/20211104_170634.jpg';
import IMG_4003 from '../../img/creation/voyage/croisiereYatchClubECE2021/IMG_4003.jpg';
import IMG_4010 from '../../img/creation/voyage/croisiereYatchClubECE2021/IMG_4010.jpg';
import IMG_4051 from '../../img/creation/voyage/croisiereYatchClubECE2021/IMG_4051.jpg';
import IMG_4153 from '../../img/creation/voyage/croisiereYatchClubECE2021/IMG_4153.jpg';
import IMG_4399 from '../../img/creation/voyage/croisiereYatchClubECE2021/IMG_4399.jpg';
import IMG_4534 from '../../img/creation/voyage/croisiereYatchClubECE2021/IMG_4534.jpg';
import IMG_4563 from '../../img/creation/voyage/croisiereYatchClubECE2021/IMG_4563.jpg';
import IMG_4713 from '../../img/creation/voyage/croisiereYatchClubECE2021/IMG_4713.jpg';
import IMG_4758 from '../../img/creation/voyage/croisiereYatchClubECE2021/IMG_4758.jpg';
import IMG_4934 from '../../img/creation/voyage/croisiereYatchClubECE2021/IMG_4934.jpg';
import IMG_4967 from '../../img/creation/voyage/croisiereYatchClubECE2021/IMG_4967.jpg';

const VoyageCroisiere = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Croisière Yacht Club ECE 2021</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_20211104_170633} alt="IMG_20211104_170633"/>
                            <img src={IMG_4003} alt="IMG_4003"/>
                            <img src={IMG_4399} alt="IMG_4399"/>
                            <img src={IMG_4713} alt="IMG_4713"/>
                            <img src={IMG_4153} alt="IMG_4153"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_4563} alt="IMG_4563"/>
                            <img src={IMG_4534} alt="IMG_4534"/>
                            <img src={IMG_20211101_112554} alt="IMG_20211101_112554"/>
                            <img src={IMG_4010} alt="IMG_4010"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_4967} alt="IMG_4967"/>
                            <img src={IMG_20211104_170634} alt="IMG_20211104_170634"/>
                            <img src={IMG_4934} alt="IMG_4934"/>
                            <img src={IMG_4051} alt="IMG_4051"/>
                            <img src={IMG_4758} alt="IMG_4758"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default VoyageCroisiere;