import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/centreInteret.css'
import {Row, Col} from 'react-bootstrap';

import fondRedirection from '../img/centreInteret/fondRedirection.png';

const CentreAudioVisuel = () => {
    return (
        <div>
            <Row className="rowCentreInteret" id="rowRigth">
                <Col xs={12} md={12} lg={4} id="sectionPhotoCentreAudioVisuel"></Col>
                <Col xs={12} md={12} lg={8} className="sectionTexteCentre" id="textAudioVisuel">
                    <h2>Audio-Visuel</h2>
                    <p>L’audio-visuel a toujours été une chose qui ma passionnée. Au même titre que l’informatique, depuis petit je m’intéresse au monde de l’audio-visuel. J’ai commencé à pratiquer comme beaucoup sur les appareils de mes parents puis par la suite j’ai investi dans mon matériel. Avec un groupe d’amis nous avons développé une mini entreprise pendant nos années de lycée afin de réaliser différents court métrage et vidéo. La réalisation d’une vidéo de présentation en drone de notre lycée a été l’un de nos plus gros projets.
                    <br/>Au fil du temps j’ai acquis beaucoup de compétence dans ce domaine et maitrise la plupart des logiciels comme Première Pro, Photoshop, Illustrator, InDesign, etc. de la suite adobe. J’ai aussi une pleine maitrise des appareils photo hybride de la marque Canon.
                    <br/><br/>Aujourd’hui cette passion me permet de capturer des moments que j’aime, garder en mémoire des souvenir et de créé des histoires captivantes.
                    <br/><br/>Une partie entière de mon site est dédié à mes créations. Je vous laisse allez la découvrir si ça vous intéresse.
                    </p>
                </Col>
            </Row>
            <Row id="rowRigth">
                <a href="/Creation" id="aRedirection">
                    <div id="sectionRedirectionCreation">
                        <img src={fondRedirection} alt="fondRedirection"/>
                        <div id="textRedirection">
                            <h3>Création</h3>
                            
                            <svg id="flecheNavigation" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 24"><defs><linearGradient id="Dégradé_sans_nom_85" y1="12" x2="18" y2="12" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#1f83db"/><stop offset="1" stop-color="#39bf00"/></linearGradient></defs>
                            <polyline points="1 23 17 12 1 1" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="url(#Dégradé_sans_nom_85)"/>
                            <polyline id="svgCreationNoir" points="1 23 17 12 1 1" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>
                        </div>
                    </div>
                    <div id="sectionRedirectionCreationxs">
                        <h3>Création</h3>
                    </div>
                </a>
                    

                
            </Row>
        </div>
    );
};

export default CentreAudioVisuel;