import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_0519 from '../../img/creation/portrait/urbain/IMG_0519.jpg';    
import IMG_1211 from '../../img/creation/portrait/urbain/IMG_1211.jpg';
import IMG_1486 from '../../img/creation/portrait/urbain/IMG_1486.jpg';
import IMG_1820 from '../../img/creation/portrait/urbain/IMG_1820.jpg';
import IMG_2131 from '../../img/creation/portrait/urbain/IMG_2131.jpg';
import IMG_2804 from '../../img/creation/portrait/urbain/IMG_2804.jpg';
import IMG_4221 from '../../img/creation/portrait/urbain/IMG_4221-4-Edit.jpg';



const PortraitUrbain = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Urbaine</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_1211} alt="IMG_3354"/>
                            <img src={IMG_1820} alt="IMG_8964"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_0519} alt="IMG_8973"/>
                            <img src={IMG_2131} alt="IMG_8973"/>
                            <img src={IMG_4221} alt="IMG_8973"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_1486} alt="IMG_9020"/>
                            <img src={IMG_2804} alt="IMG_8973"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default PortraitUrbain;