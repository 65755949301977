import React from 'react';
import '../../css/creation.css';
import { useNavigate } from 'react-router-dom';

export default class CarteType extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            titre : props.titre,
            image : props.image,
            couleur : props.couleur,
            lien : props.lien,
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        let navigate = useNavigate();
        navigate(this.state.lien);
    }

    render() {

        return(
            <a href={this.state.lien}>
                <div className="carteType" onClick = {this.handleClick} style={{backgroundImage: `url(${this.state.image})`}} id={this.state.couleur}>
                    <h2>{this.state.titre}</h2>
                </div>
            </a>
        )
    }
}