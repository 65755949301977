import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_9295 from '../../img/creation/nocturne/soireesECE2022/IMG_9295.jpg';
import IMG_0222 from '../../img/creation/nocturne/soireesECE2022/IMG_0222.jpg';
import IMG_8141 from '../../img/creation/nocturne/soireesECE2022/IMG_8141.jpg';
import IMG_8210 from '../../img/creation/nocturne/soireesECE2022/IMG_8210.jpg';
import IMG_8234 from '../../img/creation/nocturne/soireesECE2022/IMG_8234.jpg';
import IMG_8566 from '../../img/creation/nocturne/soireesECE2022/IMG_8566.jpg';



const NocturneSoireesECE = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Soirée ECE</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_8141} alt="IMG_8141"/>
                            <img src={IMG_0222} alt="IMG_0222"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_9295} alt="IMG_9295"/>
                            <img src={IMG_8210} alt="IMG_8210"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_8566} alt="IMG_8566"/>
                            <img src={IMG_8234} alt="IMG_8234"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default NocturneSoireesECE;