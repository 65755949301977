import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/professionnel.css';

import Formations from '../elements/professionnel/Formations';
import ElementMesProjets from '../elements/professionnel/ElementMesProjets';
import Experiences from '../elements/professionnel/Experiences';
import Interet from '../elements/professionnel/Interet';
import ResumerPro from '../elements/professionnel/ResumerPro';

const Professionnel = () => {
    return (
        <div>
            <div id="enteteProfessionnel" className="header"></div>
            {<ResumerPro/>}
            {<Formations/>}
            {<Experiences/>}
            {<ElementMesProjets/>}
            {<Interet/>}
        </div>
    );
};

export default Professionnel;