import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_1823 from '../../img/creation/nocturne/parviDuLouvre/IMG_1823.jpg';
import IMG_1831 from '../../img/creation/nocturne/parviDuLouvre/IMG_1831.jpg';
import IMG_1838 from '../../img/creation/nocturne/parviDuLouvre/IMG_1838.jpg';
import IMG_1844 from '../../img/creation/nocturne/parviDuLouvre/IMG_1844.jpg';
import IMG_1846 from '../../img/creation/nocturne/parviDuLouvre/IMG_1846.jpg';
import IMG_1853 from '../../img/creation/nocturne/parviDuLouvre/IMG_1853.jpg';
import IMG_1879 from '../../img/creation/nocturne/parviDuLouvre/IMG_1879.jpg';



const NocturneParviDuLouvre = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Parvis du Louvre</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_1823} alt="IMG_1823"/>
                            <img src={IMG_1831} alt="IMG_1831"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_1838} alt="IMG_1838"/>
                            <img src={IMG_1844} alt="IMG_1844"/>
                            <img src={IMG_1879} alt="IMG_1879"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_1846} alt="IMG_1846"/>
                            <img src={IMG_1853} alt="IMG_1853"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default NocturneParviDuLouvre;