import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/professionnel.css';
import {Row, Col} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const Interet = () => {
    
    let navigate = useNavigate();

    return (
        <div>
            <div id="titreInteret">
                <h2>Centres d'intérêt</h2>
            </div>
            <Row id="sectionInterer">
                <Col xs={12} md={12} lg={6} id="centreVoile" onClick={() => {navigate('/Professionnel/Centre-voile'); window.scrollTo(0, 0);}}>
                    <h2>Voile</h2>
                </Col>
                <Col xs={12} md={12} lg={6} id="centreAudioVisuel" onClick={() => {navigate('/Professionnel/Centre-audio'); window.scrollTo(0, 0);}}>
                    <h2>Audio-Visuel</h2>
                </Col>
                
            </Row>
        </div>
    );
};

export default Interet;