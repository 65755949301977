import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/creation.css';
import { Container, Row, Col} from 'react-bootstrap';

import IMG_0033 from '../../img/creation/voyage/bayonne/IMG_0033.jpg';
import IMG_0248 from '../../img/creation/voyage/bayonne/IMG_0248.jpg';
import IMG_0268 from '../../img/creation/voyage/bayonne/IMG_0268.jpg';
import IMG_0298 from '../../img/creation/voyage/bayonne/IMG_0298.jpg';
import IMG_0317 from '../../img/creation/voyage/bayonne/IMG_0317.jpg';
import IMG_0346 from '../../img/creation/voyage/bayonne/IMG_0346.jpg';
import IMG_9994 from '../../img/creation/voyage/bayonne/IMG_9994.jpg';

const VoyageBayonne = () => {
    return (
        <div>
            <div id="entete" className="header"></div>
            <div id="titreAffichagePhoto">
                <h2>Bayonne</h2>
            </div>
            <div id="sectionAffichagePhoto">
                <Container>
                    <Row>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_9994} alt="IMG_9994"/>
                            <img src={IMG_0268} alt="IMG_0268"/>
                            <img src={IMG_0033} alt="IMG_0033"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_0298} alt="IMG_0298"/>
                            <img src={IMG_0346} alt="IMG_0346"/>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <img src={IMG_0248} alt="IMG_0317"/>
                            <img src={IMG_0317} alt="IMG_0317"/>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    );
};

export default VoyageBayonne;