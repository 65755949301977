import React from 'react';

const ElementMesProjets = () => {
    return (
        <a href='/Professionnel/Mes-projets'>
            <div id="sectionProjet">
                <h2>Mes Projets</h2>
            </div>
        </a>
        
    );
};

export default ElementMesProjets;