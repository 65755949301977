import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/centreInteret.css'
import {Row, Col} from 'react-bootstrap';


const CentreVoile = () => {
    return (
        <div>
            <Row id="rowRigth">
                <Col xs={12} md={12} lg={4} id="sectionPhotoCentreVoile"></Col>
                <Col xs={12} md={12} lg={8} className="sectionTexteCentre" id="textVoile">
                    <h2>La voile</h2>
                    <p>La voile est l’une de mes plus grandes passions. Je pratique ce sport depuis que j’ai l’âge de 12ans. Je suis sportif de haut niveau dans cette discipline depuis le championnat de France de 2016 et j’ai un titre d’arbitre national en voile. L’exercice de cette mission m’apporte de précieuses compétences sur le travail d’équipe et la prise de décision.
                    <br/>J’ai eu le privilège d’intégrer une section d’excellence sportive de voile pendant mes années de collège.  J’ai développé au fil du temps dans cette section des capacités de travail en équipe et d’entre aide qui sont les valeurs de la voile.
                    <br/><br/>Aujourd’hui je continue de pratiquer ce sport pour mon loisir et j’arbitre des régates dès que l’occasion se présente.
                    </p>
                </Col>
            </Row>
        </div>
    );
};

export default CentreVoile;