import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/professionnel.css';
import { Container, Row, Col} from 'react-bootstrap';


import img2016 from'../../img/professionnel/img2016.svg';
import img2018 from'../../img/professionnel/img2018.svg';
import img2020 from'../../img/professionnel/img2020.svg';
import img2022 from'../../img/professionnel/img2022.svg';
import imgBar from'../../img/professionnel/imgBar.svg';
import imgBarDroit from'../../img/professionnel/imgBarDroit.svg';
import imgBarGauche from'../../img/professionnel/imgBarGauche.svg';
import img2016Xs from'../../img/professionnel/img2016Xs.svg';
import img2018Xs from'../../img/professionnel/img2018Xs.svg';
import img2020Xs from'../../img/professionnel/img2020Xs.svg';
import img2022Xs from'../../img/professionnel/img2022Xs.svg';

const Formations = () => {
    return (
        <div id="sectionFormation">
            <h2>Formations</h2>
            <div className="formation" id="formationLg">
                <Container>
                    <Row id="ligneImage">
                        <Col xs={12} md={6} lg={3} className="colonneImage">
                            <img src={img2016} alt="img2016" className="imageColonne"/>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="colonneImage">
                            <img src={img2018} alt="img2018"/>
                        </Col>
                        <img src={imgBarGauche} alt="imgBarGauche" id="imgBarGauche"/>
                        <Col xs={12} md={6} lg={3} className="colonneImage">
                            <img src={img2020} alt="img2020"/>
                        </Col>
                        <Col xs={12} md={6} lg={3} className="colonneImage">
                            <img src={img2022} alt="img2022"/>
                        </Col>
                        <img src={imgBar} alt="imgBar" id="imgBar"/>
                        <img src={imgBarDroit} alt="imgBarDroit" id="imgBarDroit"/>
                    </Row>
                </Container>
                    
            </div>
            <div className="formation" id="formationXs">
                <Container>
                    <div >
                        <img src={img2016Xs} alt="img2016Xs" className="imgFormationXs imgXsGauche"/>
                        <img src={img2018Xs} alt="img2018Xs" className="imgFormationXs imgXsDroit"/>
                        <img src={img2020Xs} alt="img2020Xs" className="imgFormationXs imgXsGauche"/>
                        <img src={img2022Xs} alt="img2022Xs" className="imgFormationXs imgXsDroit"/>
                    </div>
                </Container>
            </div>
        </div>
    
    );
};

export default Formations;